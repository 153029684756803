<template>
  <div>
    <div class="title">个人信息</div>
    <div class="content">
      <el-form
        label-position="right"
        label-width="89px"
        :model="formLabelAlign"
      >
        <el-form-item label="学员头像 ">
          <div class="flex">
            <el-image
              style="width: 100px; height: 100px; margin-right: 14px"
              :src="formLabelAlign.headpic"
              :preview-src-list="srcList"
            >
            </el-image>
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :limit="1"
              :before-upload="handlePreview"
            >
              <el-button size="small" type="info" plain>选择文件</el-button>
              <div slot="tip" class="el-upload__tip">
                建议尺寸200*200px或1：1，JPG、PNG格式， 图片小于3M。
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="账号/手机号">
          <el-input
            style="width: 638px"
            v-model="formLabelAlign.phone"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="微信号">
          <el-input
            style="width: 638px"
            v-model="formLabelAlign.type"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="学员姓名 ">
          <el-input
            style="width: 638px"
            v-model="formLabelAlign.username"
          ></el-input> </el-form-item
        >·
        <!-- <el-form-item label="性别">
          <el-select
            style="width: 110px"
            v-model="formLabelAlign.sex"
            placeholder="未选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="联系方式">
          <el-input
            style="width: 213px"
            v-model="formLabelAlign.type"
          ></el-input>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input
            style="width: 213px"
            v-model="formLabelAlign.type"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label=" ">
          <div class="flex">
            <div class="btn">提交</div>
            <div class="btn1">取消</div>
          </div>
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    this.formLabelAlign.headpic = this.userInfo.pic;
    this.formLabelAlign.phone = this.userInfo.phone;
    this.formLabelAlign.username = this.userInfo.name;
    this.formLabelAlign.sex = this.userInfo.sex;
    this.srcList.push(this.userInfo.headpic);
  },
  methods: {
    ...mapActions([
      "getPictureUpload",
      "getEditInfo",
      "getpictureUpload_x",
      "getsaveSelfInformation_x",
    ]),
    // 上传文件
    handlePreview(file) {
      let that = this;
      console.log(1234565465);
      var imgBase64;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        imgBase64 = this.result; //获取到base64格式图片
        console.log("imgBase64", imgBase64);
        that
          .getpictureUpload_x({
            img: imgBase64,
          })
          .then((res) => {
            if (res.successCode == 200) {
              that.formLabelAlign.headpic = res.responseBody;
              that.$nextTick(() => {
                that.$store.commit("SET_USERINFOEDIT", res.responseBody);
              });
              that
                .getsaveSelfInformation_x({
                  id: that.userInfo.id, //学员id
                  pic: res.responseBody,
                })
                .then((res) => {
                  if (res.successCode == 200) {
                    that.$message({
                      message: "修改成功",
                      type: "success",
                    });
                  }
                });
            }
          });
      };
    },
  },
  mounted() {},
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [],
      formLabelAlign: {
        name: "",
        sex: "",
        headpic: "",
      },
      options: [
        {
          value: "0",
          label: "未选择",
        },
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.content {
  background-color: #fff;
  padding: 40px 40px 500px 40px;
  box-sizing: border-box;
  margin-top: 14px;
  .btn {
    width: 163px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #137cfb;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .btn1 {
    margin-left: 15px;
    width: 163px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #edf5fe;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    border: 1px solid #d8e1ea;
    color: #7c7c7c;
  }
}
</style>
